import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import {
    createPolicyTemplateUploadPath,
    generatePolicyPlanTemplate,
    uploadPolicyAddTemplate
} from '../../../../adapters';
import { IPolicy, IPolicySubtype, IPolicyType } from '../../../../redux/slices/PolicySlice/types';
import { downloadAndGetSignedBlob, downloadSignedFile, uploadSignedFile } from '../../../../utils/common';
import { IRadioButtonOptions } from '../../../../utils/constants/PlacementSlips/RadioButtonOptions';
import { IPolicySubtypeEnum, POLICY_SUBTYPE_OPTIONS } from './constants';
import { IGeneratePolicyTemplateReq, IPolicySubtypeRadioOption } from './types';

export const getBasePoliciesDropdownOptions = (
    selectedType: IPolicyType | '',
    activePolicies?: IPolicy[]
): IDropdownItem[] => {
    if (!selectedType || !activePolicies) return [];
    return activePolicies
        .filter((p) => p.policyType === selectedType && p.policyPlacementStatus !== 'Draft')
        .map((p) => ({
            value: p.id,
            name: `${p.insurerName} (${p.policyType || 'N/A'}) (${p.id || 'N/A'})`
        }));
};

export const getPolicySubTypeOptions = (policyType: IPolicyType | ''): IPolicySubtypeRadioOption[] => {
    if (policyType) {
        return POLICY_SUBTYPE_OPTIONS.filter((option) => option.policyTypes.includes(policyType) && !option.disabled);
    }
    return [];
};

const getSubtext = (subTypeOption: IRadioButtonOptions | null, hasBenefits: boolean): string => {
    let subText = '';
    const subType = subTypeOption?.id as IPolicySubtype;
    switch (subType) {
    case IPolicySubtypeEnum.MODULAR:
        subText = hasBenefits ? ' Modular plus Benefits' : ' Modular';
        break;
    case IPolicySubtypeEnum.OPD:
    case IPolicySubtypeEnum.TOPUP:
    case IPolicySubtypeEnum.PARENTAL:
        subText = ` ${subTypeOption?.value}`;
        break;
    }
    return subText;
};

export const getTemplateDownloadTitle = (
    type: IPolicyType | '',
    subTypeOption: IRadioButtonOptions | null,
    hasBenefits: boolean
): string => {
    const subText = getSubtext(subTypeOption, hasBenefits);
    return `${type}${subText} Placement Slip`;
};

export const generateAndDownloadPolicyTemplate = async (
    policy: IGeneratePolicyTemplateReq,
    title: string
): Promise<void> => {
    const response = await generatePolicyPlanTemplate(policy);
    const data = response.data;
    const extention = data.filePath.split('.').pop() ?? 'xlsx';
    const fileName = title.replace(' ', '_').concat(`.${extention}`);
    await downloadSignedFile(data.signedURL, data.method, fileName);
};

export const submitPolicyPlanTemplate = async (
    policy: IGeneratePolicyTemplateReq,
    file: Blob
): Promise<File | null> => {
    const response = await createPolicyTemplateUploadPath(policy.companyId);
    const data = response.data;
    await uploadSignedFile(data.signedURL, data.method, file);
    const result = await uploadPolicyAddTemplate({
        ...policy,
        filePath: data.filePath
    });
    const errorFile = result?.data?.errorFile;
    if (errorFile) {
        const blob = await downloadAndGetSignedBlob(errorFile.signedURL, errorFile.method);
        const file = new File([blob], 'Error-file.xlsx', {
            type: blob.type
        });
        return file;
    }
    return null;
};
