import {
    createPolicyTemplateUploadPath,
    generatePolicyPlanEditTemplate,
    uploadPolicyEditTemplate
} from '../../../adapters';
import { downloadAndGetSignedBlob, downloadSignedFile, uploadSignedFile } from '../../../utils/common';
import { IGenerateEditPolicyTemplateReq } from './types';

export const generateAndDownloadPolicyEditTemplate = async (
    policy: IGenerateEditPolicyTemplateReq,
    title: string
): Promise<void> => {
    const response = await generatePolicyPlanEditTemplate(policy);
    const data = response.data;
    const extention = data.filePath.split('.').pop() ?? 'xlsx';
    const fileName = title.replace(' ', '_').concat(`.${extention}`);
    await downloadSignedFile(data.signedURL, data.method, fileName);
};

export const submitPolicyPlanEditTemplate = async (
    policy: IGenerateEditPolicyTemplateReq,
    file: Blob
): Promise<File | null> => {
    const response = await createPolicyTemplateUploadPath(policy.companyId);
    const data = response.data;
    await uploadSignedFile(data.signedURL, data.method, file);
    const result = await uploadPolicyEditTemplate({
        ...policy,
        filePath: data.filePath
    });
    const errorFile = result?.data?.errorFile;
    if (errorFile) {
        const blob = await downloadAndGetSignedBlob(errorFile.signedURL, errorFile.method);
        const file = new File([blob], 'Error-file.xlsx', {
            type: blob.type
        });
        return file;
    }
    return null;
};
