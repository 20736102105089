import styled from 'styled-components';

export const StyledEditPolicyForm = styled.div`
    flex: 1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledEditPolicyHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledEditPolicyHeaderTitle = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledEditPolicyHeaderContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export const StyledEditPolicyHeaderItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledEditPolicyHeaderBullet = styled.div`
    width: 5px;
    height: 5px;
    aspect-ratio: 1;
    background-color: ${(p) => p.theme.colors.defaultBorder};
    border-radius: 50%;
`;

export const StyledEditPolicyDownloadSlip = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    padding: 40px;
    gap: 32px;
    border: 0.5px solid ${(p) => p.theme.colors.planeGreenLight};
`;

export const StyledEditPolicyDownloadSlipText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const StyledEditPolicyFormContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.white};
`;

export const StyledEditPolicyFormInputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 40px;
`;

export const StyledEditPolicyFormInputsHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledEditPolicySubmitButtonContainer = styled.div`
    border-top: 2px solid ${(p) => p.theme.colors.planeGreenLight};
    padding: 24px 40px;
`;

export const StyledEditPolicyButton = styled.div`
    width: fit-content;
`;
