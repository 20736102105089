import styled from 'styled-components';

export const StyledModularPolicyOverview = styled.div`
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
`;

export const StyledModularPolicyOverviewHeader = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 28px;
    border-bottom: 1px dashed ${(p) => p.theme.colors.defaultBorder};
`;

export const StyledModularOverviewPrimary = styled.div`
    display: flex;
    align-items: center;
    padding: 28px;
    justify-content: space-between;
`;

export const StyledModuleOverviewBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 120px;
    padding: 0 28px;
    width: 100%;
`;

export const StyledModuleOverviewBodyItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    gap: 10px;
`;

export const StyledModuleOverviewBodyItemValue = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const StyledEditIcon = styled.img`
    height: 24px;
    width: 24px;
    aspect-ratio: 1;
    cursor: pointer;
`;
