import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux';
import { Card, Loader, UploadModal } from '../../../../../atoms';
import {
    StyledCardContainer,
    StyledContainer,
    StyledFormTitle,
    StyledFormTitleContainer,
    StyledRowContainer,
    StyledFieldContainer,
    StyledTabContainer,
    StyledTermsTab,
    StyledSlabsTab,
    StyledTermsTabText,
    StyledSlabsTabText,
    StyledIdentityContainer,
    StyledGreyTabContainer,
    StyledContainerText,
    StyledContainerIcons,
    StyledPolicyTermsContainer,
    StyledPolicyDetailsContainer,
    StyledPolicyDetails,
    StyledDivider,
    StyledCapitalizeText,
    StyledPolicyTerms,
    StyledPolicyTable,
    StyledDocumentMissingPrompt,
    StyledWarningIcon,
    StyledUploadButtonContainer,
    StyledUploadedDocumentContainer,
    StyledUploadedDocumentHeader,
    StyledUploadedDocuments
} from './styles';
import { PolicyIdentity, FormLabel, FormValue } from '../../../../../atoms';
import { IPolicy } from '../../../../../../redux/slices/PolicySlice/types';
import { ViewPolicyDetails } from '../../../../../../utils/constants/PlacementSlips/ViewPolicyDetails';
import { policyTermsConditions } from '../../../../../../utils/constants/PlacementSlips/PolicyTermsConditions';
import { slabDetails } from '../../../../../../utils/constants/PlacementSlips/SlabDetails';
import { DATE_FIELDS } from '../../../../../../utils/constants/PolicyTypeConstants';
import usePolicies from '../../../../../hooks/business-hooks/usePolicies';
import { useSearchParams } from 'react-router-dom';
import { ICompanyData } from '../../../../../../redux/slices/CompanySlice/types';
import { DateFormatUtils, Dialog, LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { AddNicknameModal } from '../../../../../containers/AddNicknameModal';
import { isEndoToolingEnabled } from '../../../../../../utils/featureFlags';
import { ChevronRight16x16Icon, WarningIconRed, EditIcon, ErrorIconWhite } from '../../../../../../assets/img';
import PolicyDocument from '../PolicyDocument';
import { fetchPolicy } from '../../../../../../redux/slices/PolicySlice/thunk';
import { deletePolicyDocumentFile, downloadPolicyDocumentFile, uploadPolicyDocumentFile } from '../utils';
import { useToast } from '../../../../../hooks/useToast';

const PolicyDetails: React.FunctionComponent = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const policyId = searchParams.get('id');
    const [policyDetails, loading, fetchPolicies] = usePolicies([policyId as string]);
    const [isPolicyUploadModalVisible, setIsPolicyUploadModalVisible] = useState(false);
    const [generalTermsSelected, setGeneralTermsSelected] = React.useState<boolean>(true);
    const [isAddNickNameModalVisible, setAddNickNameModalVisible] = React.useState<boolean>(false);
    const [showPolicyNumberError, setShowPolicyNumberError] = React.useState<boolean>(false);
    const [policyDetailsToDisplay, setPolicyDetailsToDisplay] = React.useState<IPolicy | null>(null);
    const [filesSelected, setFilesSelected] = React.useState<Record<string, unknown>[]>([]);

    const commonPolicyDetails: IPolicy | Record<string, string | boolean> = {};
    let viewPolicyDetails = [...ViewPolicyDetails];
    if (!isEndoToolingEnabled) {
        viewPolicyDetails = viewPolicyDetails.filter((data) => data.name !== 'nickName');
    }
    viewPolicyDetails.map((field) => {
        commonPolicyDetails[`${field.name}`] = ((policyDetailsToDisplay || {}) as unknown as Record<string, string>)[
            `${field.name}`
        ];
    });

    const definedNickName = commonPolicyDetails['nickName'] || null;
    const selectedCompany = useSelector((state: ReduxState) =>
        state.companySlice.companyList.data?.find(
            (company: ICompanyData) => company.id === policyDetailsToDisplay?.companyId
        )
    );

    React.useEffect(() => {
        const currentPolicyDetails = policyDetails.at(-1) as IPolicy;
        const orgDocumentType = currentPolicyDetails?.policyTermsAndConditions?.documentsType;

        const updatedTermsAndConditions = {
            ...currentPolicyDetails?.policyTermsAndConditions,
            sumInsuredApproach: currentPolicyDetails?.sumInsuredApproach,
            documentsType: `${orgDocumentType?.type}: ${orgDocumentType?.address}`
        };
        const updatedPolicyDetails = {
            ...currentPolicyDetails,
            policyTermsAndConditions: updatedTermsAndConditions,
            corporateName: selectedCompany?.name
        };
        setPolicyDetailsToDisplay(updatedPolicyDetails as unknown as IPolicy);
    }, [policyDetails, loading]);

    // Scroll to the top of the file
    React.useEffect(() => {
        scrollRef.current?.scrollIntoView();
    }, [scrollRef.current]);

    const valuesToDisplay = () => {
        if (generalTermsSelected && policyDetailsToDisplay) {
            return policyTermsConditions[policyDetailsToDisplay?.policyType?.toLowerCase()];
        } else if (policyDetailsToDisplay) {
            return slabDetails[policyDetailsToDisplay?.policyType?.toLowerCase()];
        }
    };

    const checkFormats = (field: string) => {
        const value = commonPolicyDetails[`${field}`];
        if (DATE_FIELDS.includes(field)) return new DateFormatUtils().formatDate(value as unknown as Date);
        if (typeof value == 'boolean') return value === true ? 'Yes' : 'No';
        else return value;
    };

    const onToggleModal = () => {
        setAddNickNameModalVisible((isAddNickNameModalVisible) => !isAddNickNameModalVisible);
    };

    const closeNickNameModal = () => {
        setAddNickNameModalVisible(false);
    };

    const closePolicyUploadModal = () => {
        setIsPolicyUploadModalVisible(false);
    };

    const handleOpenFileUploadModal = () => {
        if (policyDetailsToDisplay?.policyNumber) setIsPolicyUploadModalVisible(true);
        else setShowPolicyNumberError(true);
    };

    const handleDeletePolicyDocument = async (index: number) => {
        const policyDocuments = policyDetailsToDisplay?.policyDocuments;
        if (!policyDocuments) return;

        const length = policyDocuments.length;
        if (!length || index >= length || !policyId) return;

        await deletePolicyDocumentFile(policyId, policyDocuments[index]);

        await dispatch(fetchPolicy({ policyId, setLoadingOff: () => null }));
        fetchPolicies();
    };

    const handleUploadPolicyDocument = async (fileName: string, file: File, refreshData: boolean) => {
        if (!policyId) return;
        if (!policyDetailsToDisplay?.policyNumber) {
            toast?.displayToast(
                'error',
                'Policy number is not present',
                'Please add the policy number to upload policy documents.'
            );
            closePolicyUploadModal();
            return;
        }

        try {
            await uploadPolicyDocumentFile(policyId, fileName, file);
        } catch (error) {
            toast?.displayToast('error', 'An error occurred, please try again!', '');
        }
        if (refreshData) {
            await dispatch(fetchPolicy({ policyId, setLoadingOff: () => null }));
            fetchPolicies();
        }
    };

    const handleDownloadPolicyDocument = async (index: number) => {
        const policyDocuments = policyDetailsToDisplay?.policyDocuments;
        if (!policyDocuments) return;

        const length = policyDocuments.length;
        if (!length || index >= length || !policyId) return;

        await downloadPolicyDocumentFile(policyId, policyDocuments[index]);
    };

    return (
        (loading && !policyDetails.length && <Loader />) || (
            <StyledContainer ref={scrollRef}>
                <Modal isVisible={showPolicyNumberError} setIsVisible={setShowPolicyNumberError} isModalClosable={true}>
                    <Dialog
                        variant="vertical"
                        icon={WarningIconRed}
                        description={`
                            The policy number is not present for this policy,
                            kindly add it to upload the policy documents.
                        `}
                        title={`Policy number missing`}
                        primaryButtonText="Okay"
                        buttonVariant="error"
                        onPrimaryButtonClicked={() => setShowPolicyNumberError(false)}
                    />
                </Modal>
                <StyledCardContainer>
                    <StyledPolicyDetailsContainer>
                        <Card>
                            {policyDetailsToDisplay && (
                                <StyledIdentityContainer>
                                    <PolicyIdentity
                                        policyCategory={policyDetailsToDisplay.policyType}
                                        policyImage={policyDetailsToDisplay.insurerLogo}
                                        policyName={policyDetailsToDisplay.insurerName}
                                        policyId={policyDetailsToDisplay.id}
                                        policyStatus={policyDetailsToDisplay.policyPlacementStatus}
                                    />
                                </StyledIdentityContainer>
                            )}

                            {!definedNickName && isEndoToolingEnabled && (
                                <>
                                    <StyledGreyTabContainer>
                                        <Typography variant="medium" weight="semiBold">
                                            Nickname this policy
                                        </Typography>
                                        <StyledContainerText>
                                            <Typography variant="small" color="secondary">
                                                No nickname given
                                            </Typography>
                                            <StyledUploadButtonContainer>
                                                <LoopButton
                                                    variant="secondary"
                                                    onClick={onToggleModal}
                                                    iconSrc={ChevronRight16x16Icon}
                                                    size="small"
                                                >
                                                    Edit nickname
                                                </LoopButton>
                                            </StyledUploadButtonContainer>
                                        </StyledContainerText>
                                    </StyledGreyTabContainer>
                                    {(policyDetailsToDisplay?.policyDocuments?.length ?? 0) > 0 && <StyledDivider />}
                                </>
                            )}

                            {policyDetailsToDisplay?.policyDocuments?.length ? (
                                <StyledUploadedDocumentContainer>
                                    <StyledUploadedDocumentHeader>
                                        <Typography variant="title3">Policy Documents</Typography>
                                        <StyledUploadButtonContainer>
                                            <LoopButton
                                                variant="text"
                                                onClick={handleOpenFileUploadModal}
                                                textColor="emerald"
                                                iconSrc={EditIcon}
                                            >
                                                Update documents
                                            </LoopButton>
                                        </StyledUploadButtonContainer>
                                    </StyledUploadedDocumentHeader>

                                    <StyledUploadedDocuments>
                                        {policyDetailsToDisplay.policyDocuments.map((policyDocument, index) => (
                                            <PolicyDocument
                                                key={policyDocument}
                                                policyDocument={policyDocument}
                                                onDownload={async () => await handleDownloadPolicyDocument(index)}
                                            />
                                        ))}
                                    </StyledUploadedDocuments>
                                </StyledUploadedDocumentContainer>
                            ) : (
                                <StyledDocumentMissingPrompt>
                                    <StyledWarningIcon>
                                        <img src={ErrorIconWhite} />
                                    </StyledWarningIcon>
                                    <Typography variant="small" weight="medium">
                                        Policy documents missing
                                    </Typography>
                                    <StyledUploadButtonContainer>
                                        <LoopButton
                                            variant="secondary"
                                            onClick={handleOpenFileUploadModal}
                                            size="small"
                                        >
                                            Upload
                                        </LoopButton>
                                    </StyledUploadButtonContainer>
                                </StyledDocumentMissingPrompt>
                            )}

                            {policyId && (
                                <AddNicknameModal
                                    isVisible={isAddNickNameModalVisible}
                                    handleCloseModal={closeNickNameModal}
                                    policyId={policyId}
                                    definedNickName={definedNickName?.toString() || ''}
                                />
                            )}

                            <StyledDivider />

                            <UploadModal
                                title={`Policy documents`}
                                subtitle={
                                    <>
                                        <Typography variant="small" color="secondary">
                                            Upload{' '}
                                        </Typography>
                                        <Typography variant="small" weight="medium">
                                            Policy certificate{' '}
                                        </Typography>
                                        <Typography variant="small" color="secondary">
                                            and{' '}
                                        </Typography>
                                        <Typography variant="small" weight="medium">
                                            Policy T&C{' '}
                                        </Typography>
                                        <Typography variant="small" color="secondary">
                                            here
                                        </Typography>
                                    </>
                                }
                                uploadedFiles={policyDetailsToDisplay?.policyDocuments ?? []}
                                fileLimit={2}
                                formats={['.pdf']}
                                isVisible={isPolicyUploadModalVisible}
                                onModalClose={closePolicyUploadModal}
                                filesSelected={filesSelected}
                                setFilesSelected={setFilesSelected}
                                onDelete={handleDeletePolicyDocument}
                                onSave={handleUploadPolicyDocument}
                                onDownload={handleDownloadPolicyDocument}
                            />

                            <StyledPolicyDetails>
                                <StyledFormTitleContainer>
                                    <StyledFormTitle>
                                        <Typography variant="title3" weight="medium">
                                            Policy Details
                                        </Typography>
                                    </StyledFormTitle>
                                </StyledFormTitleContainer>

                                <StyledPolicyTable>
                                    {policyDetailsToDisplay &&
                                        viewPolicyDetails.map((field, index) => {
                                            return (
                                                <StyledRowContainer key={index}>
                                                    <StyledFieldContainer>
                                                        <FormLabel>
                                                            <Typography variant="small">{field.label}</Typography>
                                                        </FormLabel>
                                                    </StyledFieldContainer>
                                                    <StyledFieldContainer>
                                                        <FormValue>
                                                            <Typography variant="small" weight="semiBold">
                                                                {checkFormats(field.name) || 'NA'}
                                                                {field.name === 'nickName' &&
                                                                checkFormats(field.name) ? (
                                                                        <StyledContainerIcons
                                                                            src={EditIcon}
                                                                            alt={'Edit Nickname'}
                                                                            onClick={onToggleModal}
                                                                        />
                                                                    ) : null}
                                                            </Typography>
                                                        </FormValue>
                                                    </StyledFieldContainer>
                                                </StyledRowContainer>
                                            );
                                        })}
                                </StyledPolicyTable>
                            </StyledPolicyDetails>
                        </Card>
                    </StyledPolicyDetailsContainer>

                    <StyledPolicyTermsContainer>
                        <Card>
                            <StyledPolicyTerms>
                                <StyledFormTitleContainer>
                                    <StyledFormTitle>
                                        <StyledCapitalizeText>Policy Terms</StyledCapitalizeText>
                                    </StyledFormTitle>
                                </StyledFormTitleContainer>

                                <StyledTabContainer>
                                    <StyledTermsTab
                                        onClick={() => setGeneralTermsSelected(true)}
                                        $isSelected={generalTermsSelected}
                                    >
                                        <StyledTermsTabText $isSelected={generalTermsSelected}>
                                            General Terms and Conditions
                                        </StyledTermsTabText>
                                    </StyledTermsTab>
                                    <StyledSlabsTab
                                        onClick={() => setGeneralTermsSelected(false)}
                                        $isSelected={generalTermsSelected}
                                    >
                                        <StyledSlabsTabText $isSelected={generalTermsSelected}>
                                            Slab Specific Terms
                                        </StyledSlabsTabText>
                                    </StyledSlabsTab>
                                </StyledTabContainer>

                                <StyledPolicyTable>
                                    {generalTermsSelected &&
                                        policyDetailsToDisplay &&
                                        valuesToDisplay()?.map((field, index) => {
                                            return (
                                                <StyledRowContainer key={index}>
                                                    <StyledFieldContainer fixedWidth={true}>
                                                        <FormLabel>{field.label}</FormLabel>
                                                    </StyledFieldContainer>
                                                    <StyledFieldContainer fixedWidth={true}>
                                                        <FormValue>
                                                            {(
                                                                // eslint-disable-next-line max-len
                                                                policyDetailsToDisplay.policyTermsAndConditions as unknown as Record<
                                                                    string,
                                                                    string
                                                                >
                                                            )[`${field.name}`] || 'NA'}
                                                        </FormValue>
                                                    </StyledFieldContainer>
                                                </StyledRowContainer>
                                            );
                                        })}
                                    {!generalTermsSelected &&
                                        policyDetailsToDisplay &&
                                        Array.from(
                                            Array(policyDetailsToDisplay?.policyTermsAndConditions?.numberOfSlabs),
                                            (element, index) => {
                                                return (
                                                    <div key={index}>
                                                        <StyledFormTitleContainer>
                                                            <StyledFormTitle>Slab {index + 1}</StyledFormTitle>
                                                        </StyledFormTitleContainer>

                                                        {valuesToDisplay()?.map((field, i) => {
                                                            return (
                                                                <StyledRowContainer key={`Slab-${i}`}>
                                                                    <StyledFieldContainer fixedWidth={true}>
                                                                        <FormLabel>{field.label}</FormLabel>
                                                                    </StyledFieldContainer>
                                                                    <StyledFieldContainer fixedWidth={true}>
                                                                        <FormValue>
                                                                            {(
                                                                                // eslint-disable-next-line max-len
                                                                                policyDetailsToDisplay.sumAssuredSlabs as unknown as Record<
                                                                                    string,
                                                                                    any
                                                                                >
                                                                            )[index][`${field.name}`] || 'NA'}
                                                                        </FormValue>
                                                                    </StyledFieldContainer>
                                                                </StyledRowContainer>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            }
                                        )}
                                </StyledPolicyTable>
                            </StyledPolicyTerms>
                        </Card>
                    </StyledPolicyTermsContainer>
                </StyledCardContainer>
            </StyledContainer>
        )
    );
};

export default PolicyDetails;
