import { customAlphabet } from 'nanoid';
import { WorkSheet, read, utils, write } from 'xlsx';
import moment from 'moment';
import { getJsDateFromExcel } from 'excel-date-to-js';
import { IPolicyType, IPolicy, IPolicyTermsAndConditions, ISlab } from '../redux/slices/PolicySlice/types';
import { IPlacement, placementDetails } from './constants/PlacementSlips/PlacementDetails';
import { policyTermsConditions } from './constants/PlacementSlips/PolicyTermsConditions';
import { slabDetails as Slabs } from './constants/PlacementSlips/SlabDetails';
import { DATE_FIELDS } from './constants/PolicyTypeConstants';
import { IToastContext } from '../components/hooks/useToast';
import { FirebaseStorage } from '../adapters/provider';
import { DEFAULT_DATE_FORMAT } from './dateFormatService';

export const dateFormat = (date: Date): string => {
    if (date) {
        const dateToFormat = new Date(date);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        } as any;
        return dateToFormat.toLocaleDateString('en-US', options);
    }
    return '';
};

export const insuranceCardDateFormat = (seconds: number): string =>
    defaultDateFormat(seconds, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

const addLeadingZeroes = (n: number, zeroCount = 2) => ('0'.repeat(zeroCount) + n).slice(-zeroCount);
export const defaultDateFormat = (seconds: number, options?: Intl.DateTimeFormatOptions | undefined): string => {
    const date = new Date(seconds * 1000);
    if (!options) {
        return (
            addLeadingZeroes(date.getDate()) +
            '/' +
            addLeadingZeroes(date.getMonth() + 1) +
            '/' +
            addLeadingZeroes(date.getFullYear(), 4)
        );
    }
    return date.toLocaleDateString('en-US', options);
};

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
export const getDisplayTextFromUpper = (uppercaseText: string): string =>
    capitalizeFirstLetter(uppercaseText.replaceAll('_', ' ').toLowerCase());
export const capitalizeFirstLetterForEveryWord = (text: string): string =>
    text
        .split(' ')
        .map((t) => capitalizeFirstLetter(t.toLowerCase()))
        .join(' ');

export const createLoopId = (prefix: string): string => {
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
    const documentId = `${prefix}-` + nanoid().toLocaleUpperCase();
    return documentId;
};

export const excelToJson = (
    file: Blob,
    cb: (result: Record<string, unknown>[]) => void,
    sheetName?: string
    // sheetname is optional, no sheetname read first sheet
): void => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = e.target?.result;
        const workbook = read(data, {
            type: 'binary'
        });
        if (sheetName) {
            const XLRowObject = utils.sheet_to_json(workbook.Sheets[sheetName]);
            const jsonObject = JSON.stringify(XLRowObject);
            cb(JSON.parse(jsonObject));
        } else {
            const obj: Record<string, unknown>[] = [];
            const requiredSheet = Object.keys(workbook.Sheets)[0];
            const XLRowObject = utils.sheet_to_json(workbook.Sheets[requiredSheet]);
            const jsonObject = JSON.stringify(XLRowObject);
            obj.push(...JSON.parse(jsonObject));
            cb(obj);
        }
    };

    reader.onerror = (ex) => {
        cb([ex as unknown as Record<string, unknown>]);
    };

    reader.readAsBinaryString(file);
};

interface IExcelSheet {
    sheetName: string;
    data: any[];
}
export const jsonToExcel = (sheets: IExcelSheet[]): string => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const workbook = utils.book_new();
    sheets.map((sheet) => {
        if (sheet.data) {
            const worksheet = utils.json_to_sheet(sheet.data, {
                cellDates: true,
                dateNF: 'dd mmm yyyy'
            });
            const wscols = getWorksheetColumnConfig(worksheet, sheet.data);
            worksheet['!cols'] = wscols;
            utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        }
    });
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const exelBlob = new Blob([excelBuffer], { type: fileType });
    return URL.createObjectURL(exelBlob);
};
const getWorksheetColumnConfig = (ws: WorkSheet, json: Record<string, unknown>[]) => {
    const objectMaxLength: number[] = [];
    const minWidth = 12;
    for (let i = 0; i < json.length; i++) {
        const value = <any>Object.values(json[i]);
        for (let j = 0; j < value.length; j++) {
            let columnWidth = (value[j] || '').length || 0;
            if (columnWidth < minWidth) {
                columnWidth = minWidth;
            }
            objectMaxLength[j] = objectMaxLength[j] >= columnWidth ? objectMaxLength[j] : columnWidth;
        }
    }
    const wscols = objectMaxLength.map((v) => ({ width: v }));
    return wscols;
};

export const placementDataFormatter = (
    items: Record<string, unknown>[],
    policyType: IPolicyType
): Record<string, unknown> => {
    type ISlabDetails = ISlab | Record<string, unknown>;
    type ICoverage = IPolicyTermsAndConditions | Record<string, unknown>;
    type IPolicyPlan = IPolicy | Record<string, unknown>;

    const pType =
        policyType?.toLowerCase() === 'covid'
            ? policyType.charAt(0).toUpperCase() + policyType.slice(1).toLowerCase()
            : policyType;
    const fieldName = `${pType} Placement Slip Details`;
    const fieldValue = 'Value';
    const formattedData: IPolicyPlan = {};
    const coverageDetails: ICoverage = {};
    const slabDetails: ISlabDetails[] = [];
    let slabObject: ISlabDetails = {};
    let numberOfSlabs = 0;

    if (items?.[0] && Object.keys(items[0])[0] !== fieldName) {
        const error = { error: 'Please upload the correct Placement Slip' };
        return error;
    }

    for (let i = 0; i < items.length; i++) {
        if ((items[i][fieldName] as string)?.toLowerCase().trim() === 'coverage details') {
            i++;
            while ((items[i][fieldName] as string)?.toLowerCase().trim() !== 'slab specific terms') {
                if ((items[i][fieldName] as string)?.toLowerCase().trim() === 'number of slabs') {
                    numberOfSlabs = items[i][fieldValue] as number;
                }
                const keyName = convertStringToCamelCase(items[i][fieldName] as string);
                const field: IPlacement = policyTermsConditions[policyType.toLowerCase()].filter(
                    (field) => field.name === keyName
                )[0];
                const defaultValue = field?.inputType === 'number' && field.isMandatory ? null : '';
                const value =
                    field?.inputType === 'string'
                        ? ((items[i][fieldValue] ?? '') as string).toString()
                        : items[i][fieldValue];
                if (keyName === 'sumInsuredApproach') {
                    formattedData[keyName?.toString().trim()] = value ?? defaultValue;
                }
                coverageDetails[keyName.toString().trim()] = value ?? defaultValue;
                i++;
            }
            if ((items[i][fieldName] as string)?.toLowerCase().trim() === 'slab specific terms') i--;
        } else if ((items[i][fieldName] as string)?.toLowerCase().trim() === `slab specific terms`) {
            i += 2;
            for (let j = 1; j <= numberOfSlabs; j++) {
                slabObject['slabId'] = j.toString();
                while (i < items.length && (items[i][fieldName] as string)?.toLowerCase().trim() !== `slab ${j + 1}`) {
                    const keyName = convertStringToCamelCase(items[i][fieldName] as string);
                    if (keyName !== `slab${j}`) {
                        const field: IPlacement = Slabs[policyType.toLowerCase()].filter(
                            (field) => field.name === keyName
                        )[0];
                        const defaultValue = field?.inputType === 'number' ? null : '';
                        const value =
                            field?.inputType === 'string'
                                ? ((items[i][fieldValue] ?? '') as string).toString()
                                : items[i][fieldValue];
                        slabObject[keyName.toString().trim()] = value ?? defaultValue;
                    }
                    i++;
                }
                slabDetails.push(slabObject);
                slabObject = {};
            }
            break;
        } else {
            const keyName = convertStringToCamelCase(items[i][fieldName] as string);
            if (DATE_FIELDS.includes(keyName)) {
                formattedData[keyName?.toString().trim()] =
                    formatPlacementSlipDate((items[i][fieldValue] as string)?.toString() ?? '') ?? null;
            } else {
                if (keyName !== 'corporateName' && keyName !== 'livesDetails' && keyName !== 'paymentDetails') {
                    const field: IPlacement = placementDetails.filter((field) => field.name === keyName)[0];
                    const defaultValue = field?.inputType === 'number' && field.isMandatory ? null : '';
                    const value =
                        field?.inputType === 'string'
                            ? ((items[i][fieldValue] ?? '') as string).toString()
                            : items[i][fieldValue];
                    if (keyName === 'policyType') {
                        formattedData[keyName?.toString().trim()] =
                            ((items[i][fieldValue] ?? '') as string).toUpperCase() ?? defaultValue;
                    } else {
                        formattedData[keyName?.toString().trim()] = value ?? defaultValue;
                    }
                }
            }
        }
    }

    formattedData['policyTermsAndConditions'] = coverageDetails;
    formattedData['sumAssuredSlabs'] = slabDetails;
    return formattedData;
};

export const convertStringToCamelCase = (sentence: string): string => {
    const updatedKey = sentence.split(/[\/\,\-\(\.]/);
    return updatedKey[0].toLowerCase().replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (camelCaseMatch, i) => {
        if (+camelCaseMatch === 0) return '';
        return i === 0 ? camelCaseMatch.toLowerCase() : camelCaseMatch.toUpperCase();
    });
};

export const formatPlacementSlipDate = (date: string): Date | null => {
    if (date.length === 5) {
        const utcdays = Math.floor(parseInt(date) - 25569);
        const utcvalue = utcdays * 86400;
        const dateinfo = new Date(utcvalue * 1000);
        const fractionalday = parseInt(date) - Math.floor(parseInt(date)) + 0.0000001;
        let totalseconds = Math.floor(86400 * fractionalday);
        const seconds = totalseconds % 60;
        totalseconds -= seconds;
        const hours = Math.floor(totalseconds / (60 * 60));
        const minutes = Math.floor(totalseconds / 60) % 60;
        return new Date(dateinfo.getFullYear(), dateinfo.getMonth(), dateinfo.getDate(), hours, minutes, seconds);
    } else if (date.length > 6) {
        const [day, month, year] = date.split('/');
        const dobTimeStamp = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        return dobTimeStamp;
    } else {
        return null;
    }
};

export const getPolicyType =
    (name: string, isTopupPolicy: boolean, isParentalPolicy: boolean, isOPDPolicy?: boolean): string => {
        switch (name.toLowerCase()) {
        case 'gmc':
            if (isOPDPolicy) return 'GMC - OPD Policy';
            else if (isTopupPolicy && isParentalPolicy) return 'GMC - Parental Topup Policy';
            else if (isTopupPolicy) return 'GMC - Top-Up Policy';
            else if (isParentalPolicy) return 'GMC - Parental Policy';
            else return 'GMC Policy';
        case 'gpa':
            return isTopupPolicy ? 'GPA - Top-Up Policy' : 'GPA Policy';
        case 'gtl':
            return isTopupPolicy ? 'GTL - Top-Up Policy' : 'GTL Policy';
        case 'super_top_up':

            return 'Super Top Up Policy';
        case 'covid':
            return isTopupPolicy ? 'Covid - Top-Up Policy' : 'Covid Policy';
        default:
            return '';
        }
    };

export const fileDownload = (fileName: string): void => {
    const cacheBuster = Date.now(); // Generate a unique value based on the current timestamp
    const templateURL = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${fileName}`;
    const cacheBustedUrl = `${templateURL}?cache=${cacheBuster}`;
    const link = document.createElement('a');
    link.href = cacheBustedUrl;
    link.download = `${fileName.split('.').slice(0, -1).join('.')}`;
    link.click();
    link.remove();
};

export const excelDownload = (data: Record<string, unknown>[], fileName: string): void => {
    const fileLink = jsonToExcel([
        {
            sheetName: 'Sheet1',
            data
        }
    ]);
    const link = document.createElement('a');
    link.href = fileLink;
    link.download = fileName;
    link.click();
    link.remove();
};

export const excelDownloadUsingBlob = async (blobData: Blob, fileName = 'data.xlsx'): Promise<void> => {
    const url = window.URL.createObjectURL(blobData);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor element and revoking the URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

export const checkPassedDate = (date: Date): boolean => {
    const currentDate = new Date();
    if (date < currentDate) return true;
    else return false;
};

export const isValidDate = (date: unknown): boolean => date instanceof Date && !isNaN(date as any);
export const isMobileNumberValid = (mobileNumber: string): boolean => {
    return /^\d*$/.test(mobileNumber) && mobileNumber.length <= 10;
};
export const removeEmpty = (obj: Record<string, any>): Record<string, unknown> => {
    const newObj: Record<string, unknown> = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key] as Record<string, any>);
        else if (obj[key] !== undefined) newObj[key] = obj[key];
    });
    return newObj;
};

export const arraysEqual = (a: any[], b: any[]): boolean => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

const isDateInddmmyyyy = (dateString: string): boolean => {
    let isValid = false;
    const t = dateString.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (t !== null) {
        const d = +t[1];
        const m = +t[2];
        const y = +t[3];
        const date = new Date(y, m - 1, d);
        isValid = date.getFullYear() === y && date.getMonth() === m - 1 && d >= 1 && d <= 31;
    }
    return isValid;
};
export const convertExcelDateToJSDate = (excelDate: unknown): Date | string => {
    if (typeof excelDate === 'number') {
        const date = getJsDateFromExcel(excelDate);
        return moment(date).format(DEFAULT_DATE_FORMAT);
    }
    if (
        typeof excelDate === 'string' &&
        moment(excelDate, DEFAULT_DATE_FORMAT).isValid() &&
        isDateInddmmyyyy(excelDate)
    ) {
        return excelDate;
    }
    return '';
};

export const uploadFileToStorage = async (file: Record<string, unknown>, bucketName: string): Promise<string> => {
    const filePath = `/${bucketName}/${(file as Record<'name', string>).name}`;
    await FirebaseStorage.ref(filePath).put(file as unknown as Blob);
    return (file as Record<'name', string>).name;
};

export const showUnderDevelopmentToast = (toast: IToastContext | null): void => {
    toast?.displayToast('error', 'Under Development', '', 5000);
};

// If price is +0 or −0, return the String "0".
export const roundToTwo = (price: number): number => {
    return Number(Math.round((price + Number.EPSILON) * 100) / 100 + '');
};

export const formatCurrency = (price: number): string => {
    return new Intl.NumberFormat().format(roundToTwo(price));
};

export const parseResponse = async (promise: Promise<any>): Promise<any> => {
    return promise
        .then((data) => {
            return [null, data];
        })
        .catch((err) => [err]);
};
export const showApiFailureToast = (toast: IToastContext | null, message?: string): void => {
    toast?.displayToast('error', 'Something went wrong', message || '', 5000);
};
export const showApiSuccessToast = (toast: IToastContext | null, message?: string): void => {
    toast?.displayToast('success', 'Success', message || '', 5000);
};
export const getUserName = (firstName: string | null, lastName: string | null): string => {
    const username = firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : lastName || '-';
    return capitalizeFirstLetterForEveryWord(username);
};

export const alphaNumericRegex = /^[a-zA-Z0-9 _]+$/;

export const downloadBlobAsFile = async (blob: Blob, fileName: string): Promise<void> => {
    const url = window.URL.createObjectURL(blob as unknown as Blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    if (fileName.toLowerCase().endsWith('.pdf')) link.target = '_blank';
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
};

export const uploadSignedFile = async (URL: string, method: string, body: Blob): Promise<void> => {
    try {
        await fetch(URL, {
            method,
            body,
            headers: {
                'x-goog-content-length-range': '0,5242880'
            }
        });
    } catch (error) {
        throw error;
    }
};

export const downloadSignedFile = async (URL: string, method: string, fileName: string): Promise<void> => {
    try {
        const file = await fetch(URL, {
            method
        });
        const link = document.createElement('a');
        link.href = file.url;
        link.download = fileName;
        if (fileName.toLowerCase().endsWith('.pdf')) link.target = '_blank';
        link.click();
        link.remove();
    } catch (error) {
        throw error;
    }
};

export const downloadAndGetSignedBlob = async (URL: string, method: string): Promise<Blob> => {
    const response = await fetch(URL, {
        method
    });
    const file = await response.blob();
    return file;
};

export const groupArrayOfObjectsByKey =
  <T, >(list: T[], keyFunc: (object: T) => string): Record<string, T[] | undefined> =>
        list.reduce(
            (hash, obj) => ({
                ...hash,
                [keyFunc(obj)]: (hash[keyFunc(obj)] || []).concat(obj)
            }),
        {} as Record<string, T[]>
        );
