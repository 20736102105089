import { IAdapterData, IError } from '../../../types';
import { IPolicyPlanCovid, IPolicyTermsAndConditionsCovid, ISumAssuredSlabCovid } from './covidPolicy.types';
import { IDependent, IPolicyPlanGMC, IPolicyTermsAndConditionsGMC, ISumAssuredSlabGMC } from './gmcPolicy.types';
import { IPolicyPlanGPA, IPolicyTermsAndConditionsGPA, ISumAssuredSlabGPA } from './gpaPolicy.types';
import { IPolicyPlanGTL, IPolicyTermsAndConditionsGTL, ISumAssuredSlabGTL } from './gtlPolicy.types';

/** TODO:
 * Fields to migrate:
 * companyId
 * isTopUpPolicy
 * isParentalPolicy
 * topUpPolicyPlans
 */
interface IPolicyPlan {
    companyId: string;
    numberOfDependents?: number;
    finalPremium: number;
    installmentPremium?: string;
    livesDetails: string;
    policyType: IPolicyType;
    policySecondaryType?: IPolicySubtype;
    gst: number;
    createdAt: Date;
    policyPlacementStatus: IPolicyPlacementStatus;
    policyNumber?: string;
    nickName?: string;
    insurerName: string;
    transferredAmount: number;
    bankName?: string;
    paymentUtrNumber?: string;
    policyStartDate: Date | undefined;
    cdAmount?: number;
    id: string;
    policyOperationsStatus?: string;
    totalLives?: number;
    active: boolean;
    policyEndDate: Date;
    updatedAt: Date;
    tpaName: string;
    netPremium: number;
    numberOfEmployees: number;
    paymentDate?: Date;
    firstInstallmentAmount?: number;
    secondInstallmentAmount?: number;
    thirdInstallmentAmount?: number;
    fourthInstallmentAmount?: number;
    isTopUpPolicy: boolean;
    isParentalPolicy: boolean;
    isOPDPolicy?: boolean
    isCommonPool: boolean;
    sumInsuredApproach: string;
    basePolicyPlanId?: string;
    topUpPolicySlabs?: ITopUpSlab[];
    topUpPolicyName?: string;
    loading?: boolean;
    insurerLogo: string;
    finalAgreedRate: number;
    error?: string;
    isCdAccountPresent?: boolean;
    policyDocuments?: string[];
    isRaterUploaded?: boolean;
}

export interface IFamilyDefinition {
    value: string;
    dependents: IDependent[];
    totalDependents: number;
}

export interface ITopUpSlab {
    annualPremium: number;
    topUpSlabId: string;
    sumInsured: number;
    employeeContribution: number;
    familyDefinition: string;
}

export interface IPolicySlice {
    policyList: IPolicyList;
    placementFormDetails: IPolicy | null;
    crudPolicy: ICRUDPolicy;
}

export interface IPolicyList extends IAdapterData {
    data: (IPolicyPlanGPA | IPolicyPlanCovid | IPolicyPlanGMC | IPolicyPlanGTL)[] | null;
    loading: boolean;
    error: IError | null;
}

export interface ICRUDPolicy extends IAdapterData {
    loading: boolean;
    data: string | null;
    error: IError | null;
}

type IPolicyType = 'GMC' | 'GPA' | 'GTL' | 'COVID';
type IPolicySubtype = 'MODULAR' | 'IPD' | 'OPD' | 'TOPUP' | 'PARENTAL';

type IPolicy = IPolicyPlanCovid | IPolicyPlanGMC | IPolicyPlanGPA | IPolicyPlanGTL;

type IPolicyTermsAndConditions =
    | IPolicyTermsAndConditionsCovid
    | IPolicyTermsAndConditionsGMC
    | IPolicyTermsAndConditionsGPA
    | IPolicyTermsAndConditionsGTL;

type ISlab = ISumAssuredSlabCovid | ISumAssuredSlabGPA | ISumAssuredSlabGTL | ISumAssuredSlabGMC;

interface IEnrolment {
    configs: ConfigsEntity[] | null;
}

type ConfigsEntity = {
    id: number;
    companyId: string;
    policyIds?: string[] | null;
    joiningCycleStartDate: string;
    joiningCycleEndDate: string;
    enrolmentStartDate: string;
    enrolmentEndDate: string;
    updatedAt: string;
    createdAt: string;
    createdBy: string;
    updatedBy: string;
    recipients: string[];
    // eslint-disable-next-line camelcase
    carbon_copy: string[];
    completionEmailsSent: boolean;
    completed: boolean;
};

export enum IPolicyPlacementStatus {
    DRAFT = 'Draft',
    HANDOVER_TO_OPS = 'Handover to Ops',
}

export type {
    ConfigsEntity,
    IDependent,
    IEnrolment,
    IPolicy,
    IPolicyPlan,
    IPolicyPlanCovid,
    IPolicyPlanGMC,
    IPolicyPlanGPA,
    IPolicyPlanGTL,
    IPolicyTermsAndConditions,
    IPolicyTermsAndConditionsCovid,
    IPolicyTermsAndConditionsGMC,
    IPolicyTermsAndConditionsGPA,
    IPolicyTermsAndConditionsGTL,
    IPolicyType,
    IPolicySubtype,
    ISlab,
    ISumAssuredSlabCovid,
    ISumAssuredSlabGMC,
    ISumAssuredSlabGPA,
    ISumAssuredSlabGTL
};
