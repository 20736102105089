import React from 'react';
import {
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledNameContainer,
    StyledInsuranceNameAlignment,
    StyledInsuranceNameDetailContainer,
    StyledInsuranceNameBullet
} from './styles';
import { DefaultInsuranceIcon } from '../../../assets/img';
import { IPolicyIdentity } from './types';
import { LoopBadge, Typography } from '@loophealth/loop-ui-web-library';
import { IPolicyPlacementStatus } from '../../../redux/slices/PolicySlice/types';

const PolicyIdentity: React.FunctionComponent<IPolicyIdentity> = ({
    policyCategory,
    policyImage,
    policyName,
    policyId,
    policyStatus,
    policyEndDate
}) => {
    return (
        <StyledNameContainer>
            <StyledInsuranceImage src={policyImage || DefaultInsuranceIcon} alt={'Insurance Image'} />
            <StyledInsuranceNameContainer>
                <StyledInsuranceNameAlignment>
                    <StyledInsuranceName title={policyName}>
                        <Typography variant="medium" weight="medium">
                            {policyName}
                        </Typography>
                    </StyledInsuranceName>
                    {IPolicyPlacementStatus.DRAFT === policyStatus ? (
                        <LoopBadge variant="overflow" badgeType="success" size="small" text={policyStatus} />
                    ) : null}
                </StyledInsuranceNameAlignment>
                <StyledInsuranceNameDetailContainer>
                    <Typography variant="extraSmall" color="secondary">
                        {policyCategory}
                    </Typography>
                    <StyledInsuranceNameBullet />
                    <Typography variant="extraSmall" color="secondary">
                        {policyId}
                    </Typography>
                </StyledInsuranceNameDetailContainer>
                {policyEndDate && (
                    <Typography variant="extraSmall" color="secondary">
                        Policy End Date&nbsp;
                        <Typography variant="extraSmall" weight="semiBold">
                            {policyEndDate}
                        </Typography>
                    </Typography>
                )}
            </StyledInsuranceNameContainer>
        </StyledNameContainer>
    );
};

export default PolicyIdentity;
