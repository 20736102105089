import styled from 'styled-components';

export const StyledCompanyModularPolicyDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 92px;
    height: 100%;
    width: 100%;
    background-color: ${(p) => p.theme.colors.surface};
`;

export const StyledCompanyModularPrimaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
`;

export const StyledCompanyModularDetailedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
`;

export const StyledCompanyModularHeader = styled.div`
    width: 100%;
    padding: 24px 64px;
    border-bottom: 1px solid ${(p) => p.theme.colors.disableFill};
    background-color: ${(p) => p.theme.colors.white};
`;

export const StyledPolicyOverviewWrapper = styled.div`
    padding: 0 64px;
    width: 100%
`;
