import {
    Dialog,
    LoopButton,
    Modal,
    Shadow,
    Toast,
    Typography,
    UploadPillSelection
} from '@loophealth/loop-ui-web-library';
import React, { ChangeEvent, useMemo } from 'react';
import { DownloadEmeraldIcon, EditFilledIcon, TrashIconCircle1 } from '../../../assets/img';
import theme from '../../../theme';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { downloadBlobAsFile } from '../../../utils/common';
import UploadModalFile from '../../atoms/UploadModal/UploadModalFile';
import { IPolicySubtypeEnum } from '../AddPolicy/PolicyForm/constants';
import {
    StyledCheckboxContainer,
    StyledCheckboxInput,
    StyledSelectedFile,
    StyledUploadPill,
    StyledUploadPillContainer,
    StyledUploadPillHelper
} from '../AddPolicy/PolicyForm/styles';
import { getTemplateDownloadTitle } from '../AddPolicy/PolicyForm/utils';
import { fileSizeToString } from '../ValidationSummaryDetails/utils';
import {
    StyledEditPolicyButton,
    StyledEditPolicyDownloadSlip,
    StyledEditPolicyDownloadSlipText,
    StyledEditPolicyForm,
    StyledEditPolicyFormContent,
    StyledEditPolicyFormInputs,
    StyledEditPolicyFormInputsHeader,
    StyledEditPolicyHeader,
    StyledEditPolicyHeaderBullet,
    StyledEditPolicyHeaderContent,
    StyledEditPolicyHeaderItem,
    StyledEditPolicyHeaderTitle,
    StyledEditPolicySubmitButtonContainer
} from './styles';
import { IEditPolicyForm } from './types';
import { generateAndDownloadPolicyEditTemplate, submitPolicyPlanEditTemplate } from './utils';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { POLICY_ADD_ERROR_MESSAGES } from '../../pages/SPAContainer/pages/AddPolicy/constants';
import { IPolicyPlanGMC } from '../../../redux/slices/PolicySlice/types';

const EditPolicyForm: React.FC<IEditPolicyForm> = ({ policyId, companyId, policyDetails }) => {
    const toast = Toast.useToast();
    const navigate = useNavigate();
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [errorFile, setErrorFile] = React.useState<File | null>(null);
    const [isCheckboxSelected, setIsCheckboxSelected] = React.useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = React.useState<Record<string, unknown>[]>([]);
    const [fileError, setFileError] = React.useState<string>('');
    const [showDeleteFilePrompt, setShowDeleteFilePrompt] = React.useState(false);

    const isFileSelected = selectedFiles.length > 0;

    const templateDownloadTitle = useMemo(
        () =>
            getTemplateDownloadTitle(
                policyDetails.policyType,
                { id: IPolicySubtypeEnum.MODULAR, value: 'Modular' },
                !!(policyDetails as IPolicyPlanGMC).hasAdditionalBenefits
            ),
        [policyDetails]
    );

    const downloadEditTemplate = async () => {
        setIsDownloading(true);
        try {
            const policy = { companyId, policyId };
            await generateAndDownloadPolicyEditTemplate(policy, templateDownloadTitle);
            toast?.success('Template downloaded successfully');
        } catch (e) {
            const message = (e as Error).message;
            toast?.error(message, 'Something went wrong, please try again');
        }
        setIsDownloading(false);
    };

    const handleModularFlow = async () => {
        setIsSubmitting(true);
        try {
            const policy = {
                companyId,
                policyId
            };
            const errorFile = await submitPolicyPlanEditTemplate(policy, selectedFiles[0] as unknown as Blob);
            if (errorFile) {
                downloadBlobAsFile(errorFile, errorFile.name);
                setErrorFile(errorFile);
                setFileError(POLICY_ADD_ERROR_MESSAGES.TEMPLATE_ERRORS);
                toast?.error(POLICY_ADD_ERROR_MESSAGES.TEMPLATE_ERRORS_TOAST);
            } else {
                // if success show confirmation modal
                toast?.success('New GMC policy successfully created!');
                navigateToCompany();
            }
        } catch (e) {
            // todo: handle tempered file error
            const message = (e as Error).message || 'Something went wrong';
            toast?.error(POLICY_ADD_ERROR_MESSAGES.TEMPLATE_ERRORS_TOAST);
            setFileError(message);
            setSelectedFiles([]);
        }
        setIsSubmitting(false);
    };

    const handleContinueClick = () => {
        if (errorFile) {
            setErrorFile(null);
            setSelectedFiles([]);
            setFileError('');
        } else handleModularFlow();
    };

    const handleOnCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsCheckboxSelected(e.target.checked);
    };

    const handleFileDelete = () => {
        setShowDeleteFilePrompt(true);
    };

    const handleDeleteFileConfirmation = () => {
        setSelectedFiles([]);
        setShowDeleteFilePrompt(false);
    };

    const handleDownloadErrorFile = () => {
        if (!errorFile) return;
        downloadBlobAsFile(errorFile, errorFile.name);
    };

    const navigateToCompany = () => {
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.COMPANY_DETAILS.route}?id=${companyId}`);
    };

    return (
        <StyledEditPolicyForm>
            <StyledEditPolicyHeader>
                <StyledEditPolicyHeaderTitle>
                    <img src={EditFilledIcon} />
                    <Typography variant="title2" weight="semiBold">
                        Edit policy
                    </Typography>
                </StyledEditPolicyHeaderTitle>
                <StyledEditPolicyHeaderContent>
                    <StyledEditPolicyHeaderItem>
                        <Typography variant="small">
                            Policy ID&nbsp;:&nbsp;
                            <Typography variant="small" weight="semiBold">
                                {policyId}
                            </Typography>
                        </Typography>
                    </StyledEditPolicyHeaderItem>
                    <StyledEditPolicyHeaderItem>
                        <StyledEditPolicyHeaderBullet />
                        <Typography variant="small">
                            Policy Type&nbsp;:&nbsp;
                            <Typography variant="small" weight="semiBold">
                                GMC-Modular
                            </Typography>
                        </Typography>
                    </StyledEditPolicyHeaderItem>
                    <StyledEditPolicyHeaderItem>
                        <StyledEditPolicyHeaderBullet />
                        <Typography variant="small">
                            Insurer&nbsp;:&nbsp;
                            <Typography variant="small" weight="semiBold">
                                {policyDetails.insurerName}
                            </Typography>
                        </Typography>
                    </StyledEditPolicyHeaderItem>
                </StyledEditPolicyHeaderContent>
            </StyledEditPolicyHeader>
            <Shadow variant="smooth">
                <WithThemeProvider>
                    <StyledEditPolicyDownloadSlip>
                        <StyledEditPolicyDownloadSlipText>
                            <Typography variant="medium" weight="semiBold">
                                1. Download placement slip to edit
                            </Typography>
                            <Typography variant="extraSmall" color="secondary">
                                Download the latest placement slip and make your edits
                            </Typography>
                        </StyledEditPolicyDownloadSlipText>
                        <div>
                            <LoopButton
                                variant="secondary"
                                size="small"
                                iconOrder="left"
                                iconSrc={DownloadEmeraldIcon}
                                onClick={downloadEditTemplate}
                                isLoading={isDownloading}
                            >
                                Download Placement Slip
                            </LoopButton>
                        </div>
                    </StyledEditPolicyDownloadSlip>
                </WithThemeProvider>
            </Shadow>
            <Shadow variant="bottom">
                <WithThemeProvider>
                    <StyledEditPolicyFormContent>
                        <StyledEditPolicyFormInputs>
                            <StyledEditPolicyFormInputsHeader>
                                <Typography variant="medium" weight="semiBold">
                                    2. Upload edited placement slip
                                </Typography>
                                <Typography variant="extraSmall" color="secondary">
                                    Upload the edited placement slip for the changes to reflect on Genome
                                </Typography>
                            </StyledEditPolicyFormInputsHeader>
                            <StyledUploadPillContainer>
                                {errorFile ? (
                                    <StyledSelectedFile>
                                        <UploadModalFile
                                            errored
                                            onDownload={handleDownloadErrorFile}
                                            fileName={errorFile.name}
                                            fileSize={fileSizeToString(errorFile.size)}
                                            downloadButtonProps={{
                                                variant: 'outline',
                                                backgroundColor: theme.colors.white,
                                                iconSrc: undefined,
                                                size: 'small'
                                            }}
                                        />
                                    </StyledSelectedFile>
                                ) : selectedFiles.length > 0 ? (
                                    <StyledSelectedFile>
                                        <UploadModalFile
                                            onDelete={handleFileDelete}
                                            fileName={
                                                selectedFiles[0] instanceof File
                                                    ? selectedFiles[0].name
                                                    : 'Placement slip.xlsx'
                                            }
                                            fileSize={fileSizeToString(selectedFiles[0].size as number)}
                                        />
                                    </StyledSelectedFile>
                                ) : (
                                    <StyledUploadPill>
                                        <UploadPillSelection
                                            formats={['.xls', '.xlsx']}
                                            onUpload={setSelectedFiles}
                                            selected={selectedFiles}
                                            onError={(e) => setFileError(e.message)}
                                            maxFiles={1}
                                            maxSize={5}
                                        />
                                    </StyledUploadPill>
                                )}
                                {fileError && (
                                    <Typography variant="extraSmall" color="error">
                                        {fileError}
                                    </Typography>
                                )}
                                {!isFileSelected && (
                                    <StyledUploadPillHelper>
                                        <Typography variant="extraSmall">Supported Formats: XLS, XLSX</Typography>
                                        <Typography variant="extraSmall">Maximum Size: 5MB</Typography>
                                    </StyledUploadPillHelper>
                                )}
                            </StyledUploadPillContainer>
                            {!errorFile && (
                                <StyledCheckboxContainer>
                                    <StyledCheckboxInput
                                        type="checkbox"
                                        checked={isCheckboxSelected}
                                        onChange={handleOnCheckboxChange}
                                    />
                                    <Typography variant="small">
                                        I confirm that the placement slip has been approved by the Operations, Claims
                                        and CSM heads
                                    </Typography>
                                </StyledCheckboxContainer>
                            )}
                        </StyledEditPolicyFormInputs>
                        <StyledEditPolicySubmitButtonContainer>
                            <StyledEditPolicyButton>
                                <LoopButton
                                    isLoading={isSubmitting}
                                    variant={isFileSelected && isCheckboxSelected ? 'filled' : 'disabled'}
                                    onClick={handleContinueClick}
                                >
                                    {errorFile ? 'Re-upload placement slip' : 'Submit'}
                                </LoopButton>
                            </StyledEditPolicyButton>
                        </StyledEditPolicySubmitButtonContainer>
                    </StyledEditPolicyFormContent>
                </WithThemeProvider>
            </Shadow>
            <Modal isVisible={showDeleteFilePrompt} setIsVisible={setShowDeleteFilePrompt}>
                <Dialog
                    variant="vertical"
                    icon={TrashIconCircle1}
                    title="Are you sure you want to delete the placement slip?"
                    description="You’ll have to re-upload the file if you delete it."
                    primaryButtonText="Delete File"
                    buttonVariant="error"
                    onPrimaryButtonClicked={handleDeleteFileConfirmation}
                    onSecondaryButtonClicked={() => setShowDeleteFilePrompt(false)}
                    secondaryButtonText="Cancel"
                />
            </Modal>
        </StyledEditPolicyForm>
    );
};

export default EditPolicyForm;
