import React from 'react';
import { IModularPolicyDetails } from './types';
import {
    StyledCompanyModularDetailedWrapper,
    StyledCompanyModularHeader,
    StyledCompanyModularPolicyDetails,
    StyledCompanyModularPrimaryWrapper,
    StyledPolicyOverviewWrapper
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import ModularPolicyOverview from '../../../../../containers/ModularPolicyOverview';

const ModularPolicyDetails: React.FC<IModularPolicyDetails> = ({ policy, company, refetch }) => {
    return (
        <StyledCompanyModularPolicyDetails>
            <StyledCompanyModularPrimaryWrapper>
                {company && (
                    <StyledCompanyModularHeader>
                        <Typography variant="title3" weight="semiBold">
                            {company.name}
                        </Typography>
                    </StyledCompanyModularHeader>
                )}
                <StyledPolicyOverviewWrapper>
                    <ModularPolicyOverview policy={policy} refetch={refetch} />
                </StyledPolicyOverviewWrapper>
            </StyledCompanyModularPrimaryWrapper>
            <StyledCompanyModularDetailedWrapper>modular & ala carte</StyledCompanyModularDetailedWrapper>
        </StyledCompanyModularPolicyDetails>
    );
};

export default ModularPolicyDetails;
