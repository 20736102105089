import React from "react";
import { IGenericIconProps } from "./types";
import theme from "../../theme";

const ClipboardIcon: React.FC<IGenericIconProps> = ({
  width = 24,
  height = 24,
  color = theme.colors.consultViolet,
  strokeWidth = 2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth={strokeWidth}
    >
      <path d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z" fill={color}/>
      <path d="M17.2391 4.82047C17.2391 6.41047 15.9391 7.71047 14.3491 7.71047H9.64906C8.05906 7.71047 6.75906 6.41047 6.75906 4.82047C6.75906 4.26047 6.15906 3.91047 5.65906 4.17047C4.24906 4.92047 3.28906 6.41047 3.28906 8.12047V17.5305C3.28906 19.9905 5.29906 22.0005 7.75906 22.0005H16.2391C18.6991 22.0005 20.7091 19.9905 20.7091 17.5305V8.12047C20.7091 6.41047 19.7491 4.92047 18.3391 4.17047C17.8391 3.91047 17.2391 4.26047 17.2391 4.82047ZM12.3791 16.9505H7.99906C7.58906 16.9505 7.24906 16.6105 7.24906 16.2005C7.24906 15.7905 7.58906 15.4505 7.99906 15.4505H12.3791C12.7891 15.4505 13.1291 15.7905 13.1291 16.2005C13.1291 16.6105 12.7891 16.9505 12.3791 16.9505ZM14.9991 12.9505H7.99906C7.58906 12.9505 7.24906 12.6105 7.24906 12.2005C7.24906 11.7905 7.58906 11.4505 7.99906 11.4505H14.9991C15.4091 11.4505 15.7491 11.7905 15.7491 12.2005C15.7491 12.6105 15.4091 12.9505 14.9991 12.9505Z" fill={color}/>
    </svg>
    
  );
};

export { ClipboardIcon };
