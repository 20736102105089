import { DateFormatUtils, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import { ClipboardIcon } from '../../../assets/icons/ClipboardIcon';
import { ChevronDownEmeraldIcon, EditDarkIcon } from '../../../assets/img';
import theme from '../../../theme';
import { ViewModularPolicyDetails } from '../../../utils/constants/PlacementSlips/ViewPolicyDetails';
import { DATE_FIELDS } from '../../../utils/constants/PolicyTypeConstants';
import { PolicyIdentity } from '../../atoms';
import { AddNicknameModal } from '../AddNicknameModal';
import { StyledPolicyButtonsContainer } from '../CompanyPoliciesCard/styles';
import {
    StyledEditIcon,
    StyledModularOverviewPrimary,
    StyledModularPolicyOverview,
    StyledModularPolicyOverviewHeader,
    StyledModuleOverviewBody,
    StyledModuleOverviewBodyItem,
    StyledModuleOverviewBodyItemValue
} from './styles';
import { IModularPolicyOverview } from './types';

const ModularPolicyOverview: React.FC<IModularPolicyOverview> = ({ policy }) => {
    const [isAddNickNameModalVisible, setAddNickNameModalVisible] = React.useState(false);

    const onDownloadPolicyDocumentClick = () => {
        // todo: implement download policy document
        console.log('onDownloadPolicyDocumentClick');
    };

    const formatValue = (field: string, value: string | Date) => {
        if (!value) return '--';
        if (DATE_FIELDS.includes(field)) return new DateFormatUtils().formatDate(value as Date);
        if (typeof value == 'boolean') return value === true ? 'Yes' : 'No';
        if (field === 'finalPremium') return `Rs. ${value}`;
        else return value;
    };

    const primaryDetails = useMemo(() => {
        return ViewModularPolicyDetails.map((field) => ({
            ...field,
            value: formatValue(field.name, (policy as unknown as Record<string, string>)[`${field.name}`])
        }));
    }, [policy]);

    const openNickNameModal = () => {
        setAddNickNameModalVisible(true);
    };

    const closeNickNameModal = () => {
        setAddNickNameModalVisible(false);
    };
    return (
        <StyledModularPolicyOverview>
            <StyledModularPolicyOverviewHeader>
                <ClipboardIcon color={theme.colors.beyondGrey} />
                <Typography variant="title3" weight="semiBold">
                    Policy Overview
                </Typography>
            </StyledModularPolicyOverviewHeader>
            <StyledModularOverviewPrimary>
                <PolicyIdentity
                    policyImage={policy.insurerLogo}
                    policyCategory="GMC"
                    policyId={policy.id}
                    policyName={policy.insurerName}
                />
                <StyledPolicyButtonsContainer>
                    <LoopButton
                        variant="filled"
                        size="small"
                        onClick={onDownloadPolicyDocumentClick}
                        iconOrder="right"
                        iconSrc={ChevronDownEmeraldIcon}
                    >
                        Download Policy Document
                    </LoopButton>
                    <LoopButton
                        variant="outline"
                        size="small"
                        iconOrder="right"
                        iconSrc={EditDarkIcon}
                        onClick={onDownloadPolicyDocumentClick}
                    >
                        Update Document
                    </LoopButton>
                </StyledPolicyButtonsContainer>
            </StyledModularOverviewPrimary>
            <StyledModuleOverviewBody>
                {primaryDetails.map(({ name, label, value }) => (
                    <StyledModuleOverviewBodyItem key={name}>
                        <Typography variant="small" weight="semiBold">
                            {label}
                        </Typography>
                        <StyledModuleOverviewBodyItemValue>
                            <Typography variant="small">{value}</Typography>
                            {name === 'nickName' ? (
                                <StyledEditIcon src={EditDarkIcon} alt="Edit Nickname" onClick={openNickNameModal} />
                            ) : null}
                        </StyledModuleOverviewBodyItemValue>
                    </StyledModuleOverviewBodyItem>
                ))}
            </StyledModuleOverviewBody>
            <AddNicknameModal
                isVisible={isAddNickNameModalVisible}
                handleCloseModal={closeNickNameModal}
                policyId={policy.id}
                definedNickName={policy.nickName?.toString() || ''}
            />
        </StyledModularPolicyOverview>
    );
};

export default ModularPolicyOverview;
