/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchPolicyAPI,
    fetchInsurerDetailsAPI,
    createPolicyAPI,
    updatePolicyAPI
} from '../../../adapters';
import { IFamilyDefinition, IPolicy, IPolicyPlacementStatus, IPolicyPlan, ISlab } from './types';
import { updateCompanyPolicyList } from '../CompanySlice';
import { updatePolicyId, updatePolicyList } from '.';
import { IAdapterData } from '../../types';
import { DATE_FIELDS } from '../../../utils/constants/PolicyTypeConstants';
import { convertFirebaseTimestampToDate } from '../../../utils/dateFormatService';

const POLICYSLICE_ACTION_TYPES = {
    FETCH_COMPANY_POLICY: 'FETCH_COMPANY_POLICY',
    CREATE_POLICY: 'CREATE_POLICY',
    UPDATE_POLICY: 'UPDATE_POLICY'
};

export const fetchPolicy = createAsyncThunk(
    POLICYSLICE_ACTION_TYPES.FETCH_COMPANY_POLICY,
    async ({ policyId, setLoadingOff }: { policyId: string; setLoadingOff: (pId: string) => void }, { getState }) => {
        try {
            const res = await fetchPolicyAPI(policyId);
            const response = await fetchInsurerDetailsAPI(res.data.policyPlans.insurerName);
            const policies: IPolicyPlan[] = [];

            // To convert firebase timestamp to Date
            const common = res.data.policyPlans;
            Object.keys(common).map((field) => {
                if (DATE_FIELDS.includes(field)) {
                    common[`${field}`] = convertFirebaseTimestampToDate(common[`${field}`]);
                }
            });

            if (!common.policyType) {
                throw new Error('Schema Corrupt!');
            }

            // To store family definition as string value and not the family mapping
            const slabs = res.data.policyPlans.sumAssuredSlabs;
            slabs.map((slab: ISlab) => {
                const familyDefinition = (slab.familyDefinition as unknown as IFamilyDefinition)?.value;
                if (common.policyPlacementStatus !== 'Draft' && !familyDefinition) {
                    throw new Error('Schema Corrupt!');
                }
                slab.familyDefinition = familyDefinition;
            });

            const policyObject: IPolicyPlan = { ...common, sumAssuredSlabs: slabs };

            const companyPolicyList: IPolicyPlan[] = (getState() as any).policySlice.policyList.data;
            companyPolicyList
                ? policies.push(...companyPolicyList, {
                    ...policyObject,
                    id: policyId,
                    insurerLogo: response || ''
                })
                : policies.push({ ...policyObject, id: policyId, insurerLogo: response });
            setLoadingOff(policyId);
            return {
                policyList: policies as IPolicyPlan[]
            };
        } catch (e) {
            const list: IPolicyPlan[] = (getState() as any).policySlice.policyList.data || [];
            setLoadingOff(policyId);
            const policyObject: IPolicyPlan = {
                id: policyId,
                active: false,
                companyId: '',
                createdAt: new Date(),
                finalAgreedRate: 0,
                finalPremium: 0,
                gst: 0,
                insurerLogo: '',
                insurerName: '',
                isCommonPool: false,
                isParentalPolicy: false,
                isTopUpPolicy: false,
                livesDetails: '',
                netPremium: 0,
                numberOfEmployees: 0,
                policyEndDate: new Date(),
                policyType: 'COVID',
                policyPlacementStatus: IPolicyPlacementStatus.DRAFT,
                policyStartDate: new Date(),
                sumInsuredApproach: '',
                tpaName: '',
                transferredAmount: 0,
                updatedAt: new Date()
            };
            return {
                policyList: [
                    ...list,
                    {
                        ...policyObject,
                        error: 'Schema Corrupt'
                    }
                ] as IPolicyPlan[]
            };
        }
    }
);

export const createPolicy = createAsyncThunk(
    POLICYSLICE_ACTION_TYPES.CREATE_POLICY,
    async (
        { policyData, handleFormNavigation }: { policyData: IPolicy; handleFormNavigation(): void },
        { dispatch }
    ) => {
        try {
            const res: any = await createPolicyAPI(policyData);
            handleFormNavigation();
            dispatch(
                updatePolicyId({
                    policyId: res.data.id
                })
            );
            dispatch(
                updateCompanyPolicyList({
                    policyId: res.data.id,
                    companyId: policyData.companyId
                })
            );
            return {
                crudPolicy: {
                    loading: false,
                    data: true,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const updatePolicy = createAsyncThunk(
    POLICYSLICE_ACTION_TYPES.UPDATE_POLICY,
    async (
        { policyData, handleFormNavigation }: { policyData: IPolicy; handleFormNavigation: (() => void) | null },
        { dispatch }
    ) => {
        try {
            const res: any = await updatePolicyAPI(policyData.id, policyData);
            if (handleFormNavigation) handleFormNavigation();
            dispatch(
                updatePolicyList({
                    policyId: res.data.id,
                    updatedPolicy: policyData
                })
            );
            return {
                crudPolicy: {
                    loading: false,
                    data: res.data.id,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);
