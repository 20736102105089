import React from 'react';
import { Toast } from '@loophealth/loop-ui-web-library';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { CompanyCard } from '../../../../containers';
import usePolicies from '../../../../hooks/business-hooks/usePolicies';
import { POLICY_EDIT_ERROR_MESSAGES } from './constants';
import { StyledCompanyCard, StyledContainer, StyledPolicyFormContainer } from './styles';
import { IPolicySubtypeEnum } from '../../../../containers/AddPolicy/PolicyForm/constants';
import { Loader } from '../../../../atoms';
import EditPolicyForm from '../../../../containers/EditPolicyForm';

const EditPolicy: FC = () => {
    const toast = Toast.useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const policyId = searchParams.get('policyId');
    const companyId = searchParams.get('id');
    const selectedCompany = useSelector(
        (state: ReduxState) => state.companySlice.companyList.data?.filter((company) => company.id === companyId)[0]
    );
    const [policyDetails, loading] = usePolicies([policyId as string]);

    const navigateToCompany = (companyId?: string | null) => {
        if (companyId) {
            navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.COMPANY_DETAILS.route}?id=${companyId}`, {
                replace: true
            });
        } else {
            navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.COMPANY_DIRECTORY.route}`, {
                replace: true
            });
        }
    };

    useEffect(() => {
        if (!companyId) {
            toast?.error(POLICY_EDIT_ERROR_MESSAGES.NO_COMPANY_INFO);
            navigateToCompany();
        } else if (!policyId) {
            toast?.error(POLICY_EDIT_ERROR_MESSAGES.NO_POLICY_INFO);
            navigateToCompany(companyId);
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            if (policyDetails.length) {
                if (policyDetails[0].error) {
                    toast?.error(policyDetails[0].error ?? POLICY_EDIT_ERROR_MESSAGES.NO_POLICY_INFO);
                    navigateToCompany(companyId);
                } else if (policyDetails[0].policySecondaryType !== IPolicySubtypeEnum.MODULAR) {
                    toast?.warning('Navigating to Placement form to edit');
                    navigate(
                        `${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.PLACEMENT_FORM.route}` +
                            `?id=${companyId}&policyId=${policyId}`
                    );
                }
            }
        }
    }, [policyDetails, loading]);

    const isModularPolicy = policyDetails?.[0]?.policySecondaryType == IPolicySubtypeEnum.MODULAR;

    return loading ? (
        <Loader />
    ) : isModularPolicy && companyId && policyId ? (
        <StyledContainer>
            <StyledPolicyFormContainer>
                <EditPolicyForm policyDetails={policyDetails[0]} companyId={companyId} policyId={policyId} />
                <StyledCompanyCard>
                    {selectedCompany && <CompanyCard {...selectedCompany} isPlacementForm={false} />}
                </StyledCompanyCard>
            </StyledPolicyFormContainer>
        </StyledContainer>
    ) : null;
};

export default EditPolicy;
