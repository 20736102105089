import { Loader, Toast } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { IPolicySubtypeEnum } from '../../../../containers/AddPolicy/PolicyForm/constants';
import usePolicies from '../../../../hooks/business-hooks/usePolicies';
import IPDPolicyDetails from './IPDPolicy';
import ModularPolicyDetails from './ModularPolicy';
import { fetchPolicy } from '../../../../../redux/slices/PolicySlice/thunk';

const PolicyDetails: React.FC = () => {
    const toast = Toast.useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const policyId = searchParams.get('id');
    const [policyDetails, loading, fetchPolicies] = usePolicies([policyId as string]);

    const selectedCompany = useSelector((state: ReduxState) =>
        state.companySlice.companyList.data?.find(
            (company: ICompanyData) => company.id === policyDetails?.[0]?.companyId
        )
    );
    const isModularPolicy = policyDetails?.[0]?.policySecondaryType === IPolicySubtypeEnum.MODULAR;

    if (!policyId) {
        toast?.error('Policy ID not found');
        navigate(-1);
    }

    const refetch = () => {
        if (policyId) {
            dispatch(fetchPolicy({ policyId, setLoadingOff: () => null }));
            fetchPolicies();
        }
    };

    useEffect(() => {
        if (policyDetails.length && policyDetails[0].error) {
            toast?.error('Policy not found');
            navigate(-1);
        }
    }, [policyDetails]);

    return loading ? (
        <Loader />
    ) : policyId && policyDetails.length ? (
        isModularPolicy ? (
            <ModularPolicyDetails policy={policyDetails[0]} company={selectedCompany} refetch={refetch} />
        ) : (
            <IPDPolicyDetails />
        )
    ) : null;
};

export default PolicyDetails;
