import styled from 'styled-components';

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @media (max-width: 768px) {
        align-items: flex-start;
    }
`;

export const StyledInsuranceImage = styled.img`
    overflow: hidden;
    height: 48px;
    width: 48px;
    max-height: 48px;
    max-width: 48px;
    min-height: 48px;
    min-width: 48px;
`;
export const StyledInsuranceNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
    overflow: hidden;
    gap: 5px;
`;
export const StyledInsuranceNameAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
export const StyledInsuranceName = styled.div(
    (p) => `
    max-width: 400px;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.heading};
    font-weight: 700;
    margin-right: 20px;
    height: fit-content;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
);
export const StyledInsuranceCategory = styled.div(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    margin-top: 4px;
    font-weight: 600;
    text-transform: uppercase;
`
);

export const StyledInsuranceNameDetailContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledInsuranceNameBullet = styled.div`
    width: 5px;
    height: 5px;
    aspect-ratio: 1;
    background-color: ${(p) => p.theme.colors.defaultBorder};
    border-radius: 50%;
`;
